<script>
	import { checkStatus } from "../../services/userService";
	export default {
		components: {
			UserMenu: require("./UserMenu.vue").default,
		},
		props: ["drawer"],
		data() {
			return {
				user: checkStatus(),
				navDrawer: true,
			};
		},
		methods: {
			toggleNavDrawer() {
				this.navDrawer = !this.navDrawer;
				this.$emit("toggleDrawer");
			},
		},
		watch: {
			drawer() {
				this.navDrawer = this.drawer;
			},
		},
	};
</script>

<template>
	<v-app-bar app v-if="user">
		<v-app-bar-nav-icon @click="toggleNavDrawer"></v-app-bar-nav-icon>

		<v-toolbar-title>Escobar Anhängercenter</v-toolbar-title>
		<UserMenu
			@logout="$emit('logout')"
			@changePassword="$emit('changePassword')" />
	</v-app-bar>
</template>

<style scoped></style>
