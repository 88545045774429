export const tableHeaders = [
	{
		text: "Benutzername",
		align: "start",
		sortable: true,
		value: "username",
	},
	{ text: "Name", value: "name" },
	{ text: "E-Mail Addresse", value: "email" },
	{
		text: "",
		value: "actions",
		sortable: false,
		filterable: false,
		align: "end",
	},
];

export const newEntry = {
	username: "",
	name: "",
	email: "",
	password: "",
	password_confirmation: "",
};

export const props = {
	tableTitle: "Benutzerliste",
	addButtonLogo: "fas fa-user-plus",
	addButtonText: "Benutzer hinzufügen",
	deleteQuestion: "Benutzer wirklich löschen?",
	deleteMsg: "Benutzer erfolgreich gelöscht!",
	deleteError: "Fehler beim Löschen des Benutzer",
	formName: "UserForm",
	APIUrl: "user",
	type: "User",
};
