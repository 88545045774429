export const tableHeaders = [
	{
		text: "Name 1",
		align: "start",
		sortable: true,
		value: "name1",
	},
	{ text: "Name 2", value: "name2" },
	{ text: "PLZ", value: "plz" },
	{ text: "Ort", value: "city" },
	{
		text: "",
		value: "actions",
		sortable: false,
		filterable: false,
		align: "end",
	},
];

export const newEntry = {
	pass_number: "",
	name1: "",
	name2: "",
	street: "",
	plz: "",
	city: "",
	birth_date: "",
	birth_city: "",
	phone: "",
	car_number: "",
	email: "",
	driving_license_no: "",
	driving_license_class: "",
};

export const props = {
	tableTitle: "Kundenliste",
	addButtonLogo: "fas fa-user-plus",
	addButtonText: "Kunden hinzufügen",
	deleteQuestion: "Kunden wirklich löschen?",
	deleteMsg: "Kunden erfolgreich gelöscht!",
	deleteError: "Fehler beim Löschen des Kunden",
	formName: "CustomerForm",
	APIUrl: "customer",
	type: "Customer",
};
