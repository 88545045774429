import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{attrs:{"dark":"","dense":""}},_vm._l((_vm.menu),function(entry){return _c('div',{key:entry.id},[(!entry.children && entry.link)?_c(VListItem,{attrs:{"to":entry.link}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(entry.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(entry.name))])],1):_c(VListGroup,{attrs:{"prepend-icon":entry.icon,"active-class":"v-list-item--active"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(_vm._s(entry.name))])]},proxy:true}],null,true)},_vm._l((entry.children),function(child){return _c('div',{key:child.id},[(child.link)?_c(VListItem,{staticClass:"pl-12",attrs:{"to":child.link}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(child.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(child.name))])],1):_vm._e()],1)}),0)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }