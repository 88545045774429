export const tableHeaders = [
	{
		text: "Bezeichnung",
		align: "start",
		sortable: true,
		value: "name",
	},
	{ text: "Standard Anzahl", value: "defaultNumber", align: "center" },
	{ text: "Details", value: "details" },
	{
		text: "",
		value: "actions",
		sortable: false,
		filterable: false,
		align: "end",
	},
];

export const newEntry = {
	name: "",
	details: "",
	defaultNumber: "",
};

export const props = {
	tableTitle: "Zubehörliste",
	addButtonLogo: "fas fa-plus",
	addButtonText: "Zubehör hinzufügen",
	deleteQuestion: "Zubehör wirklich löschen?",
	deleteMsg: "Zubehör erfolgreich gelöscht!",
	deleteError: "Fehler beim Löschen des Zubehörs",
	formName: "EquipmentForm",
	APIUrl: "equipment",
	type: "Equipment",
};
