<script>
	import { checkStatus, userLogout } from "../../services/userService";
	export default {
		data() {
			return {
				user: checkStatus(),
				userMenuItems: [
					{
						name: "Passwort ändern",
						icon: "fas fa-key",
						method: "this.openChangePw()",
					},
					{
						name: "Logout",
						icon: "fas fa-power-off",
						method: "this.logout()",
					},
				],
			};
		},
		methods: {
			itemClicked(item) {
				if (item.method) {
					eval(item.method);
				}
			},
			logout() {
				this.$emit("logout");
				userLogout();
			},
			openChangePw() {
				this.$emit("changePassword");
			},
		},
	};
</script>

<template>
	<div class="mr-0 ml-auto mr-md-5">
		<v-menu transition="slide-y-transition" bottom v-if="user" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="primary"
					dark
					elevation="2"
					v-bind="attrs"
					v-on="on">
					<v-icon dark class="mr-2"> fas fa-user </v-icon>
					{{ user.username }}
				</v-btn>
			</template>
			<v-list dense>
				<v-list-item
					v-for="(item, i) in userMenuItems"
					:key="i"
					@click="itemClicked(item)"
					link>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<style scoped>
	.v-btn {
		text-transform: capitalize !important;
	}
</style>
