import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";

import VueClipboards from "vue-clipboards";

const vuetify = new Vuetify();

Vue.use(Vuetify);
Vue.use(VueClipboards);

Vue.use(TiptapVuetifyPlugin, {
	vuetify,
	iconsGroup: "fa",
});

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: colors.blue.darken2, // #E53935
				secondary: colors.blueGrey.darken2, // #FFCDD2
				accent: colors.blue.base, // #3F51B5
			},
		},
	},
});
