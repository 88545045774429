<script>
	import { patchAPI } from "@/services/callAPI";

	export default {
		props: ["user", "dialog"],
		data() {
			return {
				userPassword: {
					oldPassword: "",
					newPassword: "",
					newPassword_confirmation: "",
				},
				errorObject: {},
			};
		},
		computed: {
			errors() {
				return this.errorObject;
			},
		},
		methods: {
			save() {
				patchAPI("pwChange", this.userPassword)
					.then((data) => {
						data.message =
							'Passwort für "' + data.username + '" geändert.';
						this.$emit("saveSuccess", data);
						this.close();
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errorObject = error.response.data.errors;
						}
						if (error.response.status === 403) {
							this.errorObject = {
								oldPassword: ["Das Passwort ist falsch!"],
							};
						}
					});
			},
			close() {
				this.$emit("close");
			},
		},
	};
</script>

<template>
	<v-card>
		<v-card-title>
			<span class="text-h5"
				>Passwort für "{{ user.username }}" ändern</span
			>
		</v-card-title>

		<v-card-text>
			<v-container>
				<v-row justify="space-around" class="mb-10">
					<v-col cols="12" md="10" class="mt-6">
						<v-text-field
							v-model="userPassword.oldPassword"
							label="Aktuelles Passwort"
							dense
							type="password"
							class="mb-4"
							:error-messages="errors.oldPassword"></v-text-field>
						<v-text-field
							v-model="userPassword.newPassword"
							label="Neues Passwort"
							dense
							class="mb-4"
							type="password"
							:error-messages="errors.newPassword"></v-text-field>
						<v-text-field
							v-model="userPassword.newPassword_confirmation"
							label="Neues Passwort Wiederholen"
							dense
							type="password"
							:error-messages="
								errors.newPassword_confirmation
							"></v-text-field>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="red darken-2" text @click="close"> Abbrechen </v-btn>
			<v-btn color="success darken-2" text @click="save">
				Speichern
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
