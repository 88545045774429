export const tableHeaders = [
	{
		text: "Nummer",
		align: "start",
		sortable: true,
		value: "reservation_number",
	},
	{ text: "Kunde", value: "customer_name1" },
	{ text: "Anhänger", value: "vehicle_title" },
	{ text: "Kennzeichen", value: "vehicle_plateNumber" },
	{ text: "Abholdatum", value: "collect_date" },
	{ text: "Rückgabedatum", value: "return_date" },
	{ text: "Abhol Adresse", value: "collect_address.name" },
	{
		text: "Aktionen",
		value: "actions",
		sortable: false,
		filterable: false,
		align: "end",
	},
];

export const newEntry = {
	offerNumber: "",
	reservationNumber: "",
	contractNumber: "",
	offerDate: "",
	reservationDate: "",
	contractDate: "",
	currentState: "",
	collectDate: "",
	collectTime: "",
	returnDate: "",
	returnTime: "",
	totalPrice: "",
	nettoPrice: "",
	taxValue: "",
	reservationDepositValue: "",
	reservationDepositDate: "",
	reservationDepositType: "",
	reservationDepositRecieved: false,
	finalPaymentValue: "",
	finalPaymentDate: "",
	finalPaymentType: "",
	finalPaymentRecieved: false,
	contractBail: "",
	contractBailDate: "",
	contractBailType: "",
	contractBailRecieved: false,
	contractBailReturnType: "",
	contractBailReturned: false,
	comment: "",

	customer_id: "",
	customer_pass_number: "",
	customer_name1: "",
	customer_name2: "",
	customer_street: "",
	customer_plz: "",
	customer_city: "",
	customer_birth_date: "",
	customer_birth_city: "",
	customer_phone: "",
	customer_car_number: "",
	customer_email: "",
	customer_driving_license_no: "",
	customer_driving_license_class: "",
	customer_comment: "",

	driver_id: "",
	driver_pass_number: "",
	driver_name1: "",
	driver_name2: "",
	driver_street: "",
	driver_plz: "",
	driver_city: "",
	driver_birth_date: "",
	driver_birth_city: "",
	driver_phone: "",
	driver_car_number: "",
	driver_email: "",
	driver_driving_license_no: "",
	driver_driving_license_class: "",
	driver_comment: "",

	vehicle_id: "",
	vehicle_title: "",
	vehicle_plateNumber: "",
	vehicle_chassisNumber: "",
	vehicle_totalWeight: "",
	vehicle_usableWeight: "",
	vehicle_loadingSize: "",
	vehicle_comment: "",

	vat: "",
	offer_note: "",
	reservation_note: "",
	contract_note: "",
	document_footer: "",
	contactdata: "",

	collect_address_id: "",

	selectedEquipmentList: [],
};

export const props = {
	tableTitle: "Reservierungen",
	addButtonLogo: "fas fa-plus",
	addButtonText: "Reservierung hinzufügen",
	deleteQuestion: "Reservierung wirklich löschen?",
	deleteMsg: "Reservierung erfolgreich gelöscht!",
	deleteError: "Fehler beim Löschen der Reservierung",
	formName: "DocumentForm",
	APIUrl: "reservation",
	type: "Reservation",
};
