<script>
	import { checkStatus } from "@/services/userService";
	import { fetchFromAPI } from "@/services/callAPI";
	export default {
		components: {
			NavMenu: require("./NavMenu.vue").default,
		},
		props: ["drawer"],
		data() {
			return {
				user: checkStatus(),
				menu: [],
			};
		},
		mounted() {
			if (checkStatus()) {
				console.log(checkStatus());
				fetchFromAPI("nav").then((data) => {
					this.menu = data;
				});
			}
		},
	};
</script>

<template>
	<v-navigation-drawer class="secondary" v-model="drawer" app>
		<div class="pa-2">
			<v-img alt="Escobar Logo" src="/site-logo.jpg" contain></v-img>
		</div>

		<v-divider class="grey lighten-1"></v-divider>

		<NavMenu :menu="menu" />
	</v-navigation-drawer>
</template>

<style scoped></style>
