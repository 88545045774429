import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { checkStatus } from "../services/userService";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.path != "/login" && to.path != "/json" && !checkStatus()) {
		next("/login");
	} else {
		next();
	}
});

export default router;
