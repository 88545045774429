import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Passwort für \""+_vm._s(_vm.user.username)+"\" ändern")])]),_c(VCardText,[_c(VContainer,[_c(VRow,{staticClass:"mb-10",attrs:{"justify":"space-around"}},[_c(VCol,{staticClass:"mt-6",attrs:{"cols":"12","md":"10"}},[_c(VTextField,{staticClass:"mb-4",attrs:{"label":"Aktuelles Passwort","dense":"","type":"password","error-messages":_vm.errors.oldPassword},model:{value:(_vm.userPassword.oldPassword),callback:function ($$v) {_vm.$set(_vm.userPassword, "oldPassword", $$v)},expression:"userPassword.oldPassword"}}),_c(VTextField,{staticClass:"mb-4",attrs:{"label":"Neues Passwort","dense":"","type":"password","error-messages":_vm.errors.newPassword},model:{value:(_vm.userPassword.newPassword),callback:function ($$v) {_vm.$set(_vm.userPassword, "newPassword", $$v)},expression:"userPassword.newPassword"}}),_c(VTextField,{attrs:{"label":"Neues Passwort Wiederholen","dense":"","type":"password","error-messages":_vm.errors.newPassword_confirmation},model:{value:(_vm.userPassword.newPassword_confirmation),callback:function ($$v) {_vm.$set(_vm.userPassword, "newPassword_confirmation", $$v)},expression:"userPassword.newPassword_confirmation"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red darken-2","text":""},on:{"click":_vm.close}},[_vm._v(" Abbrechen ")]),_c(VBtn,{attrs:{"color":"success darken-2","text":""},on:{"click":_vm.save}},[_vm._v(" Speichern ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }