import axios from "axios";
import { authHeader } from "./userService";

const BASE_URL = process.env.VUE_APP_BASE_API_URL;

const options = {
	url: BASE_URL,
	params: {},
	headers: {},
};

export const fetchFromAPI = async (url) => {
	options.headers = authHeader();
	const { data } = await axios.get(`${BASE_URL}${url}`, options);
	return data;
};

export const postToAPI = async (url, payload) => {
	options.headers = authHeader();
	const { data } = await axios.post(`${BASE_URL}${url}`, payload, options);
	return data;
};

export const deleteFromAPI = async (url) => {
	options.headers = authHeader();
	const { data } = await axios.delete(`${BASE_URL}${url}`, options);
	return data;
};

export const patchAPI = async (url, payload) => {
	options.headers = authHeader();
	const { data } = await axios.patch(`${BASE_URL}${url}`, payload, options);
	return data;
};
