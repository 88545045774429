import VueJwtDecode from "vue-jwt-decode";
import router from "@/router";
import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_API_URL;

export const checkStatus = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	if (user) {
		const decode = VueJwtDecode.decode(user.token);
		user.username = decode.username;
		const expire = new Date(decode.exp * 1000);
		const now = new Date();
		if (expire < now) {
			localStorage.removeItem("user");
			return false;
		}
		return { username: user.username };
	}
	return false;
};

export const authHeader = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	if (user && user.token) {
		return { Authorization: "Bearer " + user.token };
	} else {
		return {};
	}
};

export const userLogin = async (user) => {
	const { data } = await axios.post(`${BASE_URL}login`, {
		username: user.username,
		password: user.password,
	});
	return data;
};

export const userLogout = () => {
	localStorage.removeItem("user");
	setTimeout(() => router.push({ path: "/login" }), 100);
};
