<script>
	import { checkStatus } from "@/services/userService";
	import PasswordChange from "./components/Forms/PasswordChangeDialog.vue";

	export default {
		components: {
			TopBar: require("@/components/App/TopBar.vue").default,
			NavDrawer: require("@/components/App/NavDrawer.vue").default,
			PasswordChange,
		},
		data() {
			return {
				user: checkStatus(),
				drawer: true,
				passwordChange: false,
				snackbarContent: "",
				snackbar: false,
			};
		},
		methods: {
			updateUser(user) {
				this.user = user;
			},
			toggleDrawer() {
				this.drawer = !this.drawer;
			},
			saveSuccess(data) {
				this.snackbarContent =
					"<i class='far fa-check-circle'></i> " + data.message;

				this.snackbar = true;
			},
		},
	};
</script>

<template>
	<v-app id="inspire">
		<v-dialog v-model="passwordChange" max-width="600px">
			<PasswordChange
				:user="user"
				:dialog="passwordChange"
				@close="passwordChange = false"
				@saveSuccess="saveSuccess" />
		</v-dialog>
		<NavDrawer :drawer="drawer" v-if="user" />
		<TopBar
			v-if="user"
			:drawer="drawer"
			@logout="user = false"
			@toggleDrawer="toggleDrawer"
			@changePassword="passwordChange = true" />
		<v-main class="grey lighten-4">
			<router-view
				@login="updateUser"
				:key="$route.fullPath"></router-view>
		</v-main>
		<v-snackbar
			top
			vertical
			:class="'snackbar'"
			content-class="snackbarText"
			:timeout="2500"
			color="success"
			v-model="snackbar">
			<span v-html="snackbarContent"></span>
		</v-snackbar>
	</v-app>
</template>

<style lang="css">
	.v-btn {
		text-transform: none !important ;
	}
</style>
