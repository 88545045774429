import {
	customerProps,
	userProps,
	trailerProps,
	offerProps,
	reservationProps,
	contractProps,
	equipmentProps,
} from "@/Definitions";

const routes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () =>
			import(
				/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"
			),
	},
	{
		path: "/login",
		name: "Login",
		component: () =>
			import(/* webpackChunkName: "login" */ "../views/Login.vue"),
	},
	{
		path: "/json",
		name: "JSON",
		component: () =>
			import(/* webpackChunkName: "JSON" */ "../views/JSON.vue"),
	},
	{
		path: "/users",
		name: "User",
		component: () =>
			import(/* webpackChunkName: "User" */ "../views/TableView.vue"),
		props: userProps,
	},
	{
		path: "/customers",
		name: "Customer",
		component: () =>
			import(/* webpackChunkName: "Customer" */ "../views/TableView.vue"),
		props: customerProps,
	},
	{
		path: "/trailers",
		name: "Trailer",
		component: () =>
			import(/* webpackChunkName: "Trailer" */ "../views/TableView.vue"),
		props: trailerProps,
	},
	{
		path: "/equipment",
		name: "Equipment",
		component: () =>
			import(
				/* webpackChunkName: "Equipment" */ "../views/TableView.vue"
			),
		props: equipmentProps,
	},
	{
		path: "/options",
		name: "Options",
		component: () =>
			import(/* webpackChunkName: "Options" */ "../views/Options.vue"),
	},
	{
		path: "/offers",
		name: "offer",
		component: () =>
			import(/* webpackChunkName: "offer" */ "../views/TableView.vue"),
		props: offerProps,
	},

	{
		path: "/reservations",
		name: "reservation",
		component: () =>
			import(
				/* webpackChunkName: "reservation" */ "../views/TableView.vue"
			),
		props: reservationProps,
	},

	{
		path: "/contracts",
		name: "contract",
		component: () =>
			import(/* webpackChunkName: "contract" */ "../views/TableView.vue"),
		props: contractProps,
	},
];

export default routes;
