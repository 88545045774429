import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mr-0 ml-auto mr-md-5"},[(_vm.user)?_c(VMenu,{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","elevation":"2"}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v(" fas fa-user ")]),_vm._v(" "+_vm._s(_vm.user.username)+" ")],1)]}}],null,false,277147812)},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.userMenuItems),function(item,i){return _c(VListItem,{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.itemClicked(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }