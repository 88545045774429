export const tableHeaders = [
	{
		text: "Anhänger",
		align: "start",
		sortable: true,
		value: "title",
	},
	{ text: "Kennzeichen", value: "plateNumber", align: "start", width: "12rem",},

	
	{
		text: "zul. GG.",
		value: "totalWeight",
		align: "end",
		width: "7rem",
	},

	{
		text: "Nutzlast",
		value: "usableWeight",
		align: "end",
		width: "7rem",
	},
	{ text: "Lademaße", value: "loading_size", align: "end", width: "12rem", },
	{ text: "Tüv", value: "tuev", align: "center" },

	{
		text: "",
		value: "actions",
		sortable: false,
		filterable: false,
		align: "end",
	},
];

export const newEntry = {
	title: "",
	plateNumber: "",
	totalWeight: "",
	loadingSize: "",
	tuev: "",
	chassisNumber: "",
	usableWeight: "",
};

export const props = {
	tableTitle: "Anhängerliste",
	addButtonLogo: "fas fa-user-plus",
	addButtonText: "Anhänger hinzufügen",
	deleteQuestion: "Anhänger wirklich löschen?",
	deleteMsg: "Anhänger erfolgreich gelöscht!",
	deleteError: "Fehler beim Löschen des Anhänger",
	formName: "TrailerForm",
	APIUrl: "trailer",
	type: "Trailer",
};
